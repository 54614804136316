body {
  margin: 0;
  font-family: 'Tahoma';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Tahoma',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Effectra';
  src: local('FiraSans'), url(./fonts/effectramedium.ttf) format('truetype');
}

.my-auto {
  margin: auto 0;
}

.calendly-inline-widget::-webkit-scrollbar {
    width: 1em;
}
.calendly-inline-widget::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.calendly-inline-widget::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.error {
  font-size: 14px;
  margin-top: 4px;
  color: red;
}